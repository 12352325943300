import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as XIcon } from "../assets/img/x.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "We Made Apps" ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }



  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          {/* <Col xs={12} md={6} xl={7}> */}
          <TrackVisibility partialVisibility>
              {({ isVisible,hasBeenVisible }) =>
                <div className={isVisible && !hasBeenVisible ? "animate__animated animate__fadeIn" : ""}>
                {/* <span className="tagline2">X </span> */}
                <XIcon className="tagline2" width="128" height="128" />

               

                { <h1 style={{ color: 'black' }}>{`Square`}</h1> }
                { <h1 style={{ color: 'black' }}>{`Studio`}</h1> }
                <br></br><br></br>
                { <h1 style={{ color: 'black', fontSize: '50px' }}>{`We Made Apps`}</h1> }
                 
                  {/* <button onClick={() => console.log('connect')}>Check Apps <ArrowRightCircle size={25} /></button> */}
              </div>}
            </TrackVisibility>

        </Row>
      </Container>
    </section>
  )
}
