import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Footer } from "./components/Footer";
import { Privacy } from "./components/Privacy";



function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Privacy />
      <Footer />
    </div>
  );
}


export default App;
