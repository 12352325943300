import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Privacy = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Web Developer", "Web Designer", "UI/UX Designer" ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="privacy" id="privacy">
      <Container>
        <Row className="aligh-items-center">
         
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "" : ""}>
             
             <span className="tagline">
              <h1>Privacy Policy </h1></span>
              <br></br>
             
  <p>This privacy policy is applicable to the Apps app (hereinafter referred to as "Application") for mobile devices,
    which was developed by XsquareStudio
    (hereinafter referred to as "Service Provider") as a an Ad Supported service. This service is provided "AS IS".</p>
    <br></br><strong>What information does the Application obtain and how is it used?
  </strong>

  <br></br><strong>Automatically Collected Information</strong>
  <p>In addition, the Application may collect certain information automatically, including, but not limited to, the type
    of mobile device you use, your mobile
    devices unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile
    Internet browsers you use, and information about the way you use the Application.</p>
    <br></br><strong>Does the Application collect precise real time location information of the device?</strong>
    <div >
    <p>This Application may collects your device's location, which helps the Service Provider determine your approximate
      geographical location and make use of in below ways:</p>
    <ul>
      <li>Geolocation Services: The Service Provider utilizes location data to provide features such as personalized
        content, relevant recommendations, and location-based services.</li>
      <li>Analytics and Improvements: Aggregated and anonymized location data helps the Service Provider to analyze user
        behavior, identify trends, and improve the overall performance and functionality of the Application.</li>
      <li>Third-Party Services: Periodically, the Service Provider may transmit anonymized location data to external
        services. These services assist them in enhancing the Application and optimizing their offerings.</li>
    </ul>
  </div>
  <br></br><strong>Do third parties see and/or have access to information obtained by the Application?</strong>
  <p>Only aggregated, anonymized data is periodically transmitted to external services to aid the Service Provider in
    improving the Application and their service. The Service Provider may share your information with third parties in
    the ways that are described in this privacy statement.</p>
  <div>
    <p>Please note that the Application utilizes third-party services that have their own Privacy Policy about handling
      data. Below are the links to the Privacy Policy of the third-party service providers used by the Application:</p>
    <ul>
      <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play
          Services</a></li>
      <li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank"
          rel="noopener noreferrer">AdMob</a></li>
      <li><a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">Google
          Analytics for Firebase</a></li>
      <li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase
          Crashlytics</a></li>
      <li><a href="https://www.weplananalytics.com/en/sdk_disclaimer/privacy-policy" target="_blank"
          rel="noopener noreferrer">Weplan Analytics' SDK</a></li>
   </ul>
  </div>

  <p>The Service Provider may disclose User Provided and Automatically Collected Information:</p>
  <ul>
    <li>as required by law, such as to comply with a subpoena, or similar legal process;</li>
    <li>when they believe in good faith that disclosure is necessary to protect their rights, protect your safety or the
      safety of others, investigate fraud, or respond to a government request;</li>
    <li>with their trusted services providers who work on their behalf, do not have an independent use of the
      information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>
  </ul>
  <strong>Partner CUMBERLAND SOLUTIONS S.L. (Weplan)</strong>
  <p>Cumberland Solutions S.L. (Weplan Analytics) is a crowdsourcing mobile data and analytics company focused on the
    telecommunications industry.
    Weplan Analytics collaborates with mobile operators, TowerCos and Telecommunications Regulatory Agencies in order to
    improve their network coverage through Business Intelligence Reports and Network Quality of Experience Dashboards.
    Using this app and permitting Weplan Analytics to measure network performance linked with location data (from GPS or
    network-based location) in a pseudonymized manner, may help improve the performance of the networks you use.
    Weplan Analytics doesn't collect sensitive personal information like name, phone number or email, nor personal
    identifiers like Mobile Advertising ID, IMEI, IMSI or ICCID to ensure data is not attributed to particular and
    identifiable consumers.
    To read more about what data is collected by Weplan Analytics and its privacy policy, please follow the <a
      href="https://www.weplananalytics.com/en/sdk_disclaimer/privacy-policy">link</a>.</p>
  

  <p></p><br></br><strong>What are my opt-out rights?</strong>
  <p>You can halt all collection of information by the Application easily by uninstalling the Application. You may use
    the standard uninstall processes as may be available as part of your mobile device or via the mobile application
    marketplace or network.</p><br></br><strong>Data Retention Policy, Managing Your Information</strong>
  <p>The Service Provider will retain User Provided data for as long as you use the Application and for a reasonable
    time thereafter. The Service Provider will retain Automatically Collected information for up to 24 months and
    thereafter may store it in aggregate. If you'd like the Service Provider to delete User Provided Data that you have
    provided via the Application, please contact them at xsquarestudiodev@gmail.com and we will respond in a reasonable
    time. Please note that some or all of the User Provided Data may be required in order for the Application to
    function properly.</p><br></br><strong>Children</strong>
  <p>The Service Provider does not use the Application to knowingly solicit data from or market to children under the
    age of 13.</p>
  <div>
    <p>The Application does not address anyone under the age of 13. The Service Provider does not knowingly collect
      personally identifiable information from children under 13 years of age. In the case the Service Provider discover
      that a child under 13 has provided personal information, the Service Provider will immediately delete this from
      their servers. If you are a parent or guardian and you are aware that your child has provided us with personal
      information, please contact the Service Provider (xsquarestudiodev@gmail.com) so that they will be able to take
      the necessary actions.</p>
  </div><br></br><strong>Security</strong>
  <p>The Service Provider are concerned about safeguarding the confidentiality of your information. The Service Provider
    provide physical, electronic, and procedural safeguards to protect information we process and maintain. For example,
    we limit access to this information to authorized employees and contractors who need to know that information in
    order to operate, develop or improve their Application. Please be aware that, although we endeavor provide
    reasonable security for information we process and maintain, no security system can prevent all potential security
    breaches.</p><br></br><strong>Changes</strong>
  <p>This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you of any
    changes to the Privacy Policy by updating this page with the new Privacy Policy. You are advised to consult this
    Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.</p><br></br>
  <p>This privacy policy is effective as of 2024-03-27</p><br></br><strong>Your Consent</strong>
  <p>By using the Application, you are giving your consent to the Service Provider processing of your information as set
    forth in this Privacy Policy now and as amended by us. "Processing,” means using cookies on a computer/hand held
    device or using or touching information in any way, including, but not limited to, collecting, storing, deleting,
    using, combining and disclosing information.</p><br></br><strong>Contact us</strong>
  <p>If you have any questions regarding privacy while using the Application, or have questions about the practices,
    please contact the Service Provider via email at <a
      href="mailto:xsquarestudiodev@gmail.com">xsquarestudiodev@gmail.com</a></p>
 
                 
              </div>}
            </TrackVisibility>
          
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                 
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
        <br></br>

      </Container>
    </section>
  )
}
